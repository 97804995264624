@media only screen and (maxWidth: 767px) {
    .token-img-container {
      display: none;
      margin-top: 15%;
    }
  }
  
  @media only screen and (min-width: 768px) {
    .token-img-container {
      display: block;
      margin-top: 15%;
    }
  }
  .app-container {
    background-color: #141321;
  }
  @media (max-width: 767px) {
    .mobile-container {
      position: relative;
      padding-top: 5%;
      padding-bottom: 5%;
      background-color: #000000;
      margin-top: 50%;
      margin-bottom: 0%;
    }
    .buttonOnMobile{
      margin-right: 8%;
    }
  }
    
  .token-img {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
  .first-img {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
    padding-top: 3%;
  }
  .price-info {
    font-size: 24px;
    margin-bottom: 30px;
  }
  
  .guess-label {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .guess-input {
    font-size: 24px;
    padding: 5px 10px;
    margin-left: 10px;
    border-radius: 5px;
    border: none;
    box-shadow: 2px 2px 10px rgba(255, 255, 255, 0.1);
  }
  
  .guess-button {
    font-size: 24px;
    padding: 5px 20px;
    margin-left: 20px;
    border-radius: 5px;
    border: none;
    background-color: #0070f3;
    color: white;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  .guess-button:hover {
    background-color: #0052cc;
  }
  
  .guess-feedback {
    font-size: 24px;
    margin-top: 30px;
  }
  .content {
    margin: 50px 0;
  }
  
  .left-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .right-panel img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
    margin-top: 20px;
  }
  .red-box {
    background-color: #141321;
    color: white;
    padding: 20px;
    margin: 15%;
    border: 1px solid #5a59d8;
    height: 100%;
    width: 200%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out;
  }
    
  .red-box:hover {
    background-color: #5a59d8;
  }

  .box {
    background-color: #141321;
    color: white;
    padding: 20px;
    margin: 2%;
    border: 1px solid #5a59d8;
    height: 100%;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out;
  }
  .box:hover {
    background-color: #5a59d8;
  }

  .box-wide {
    display: block;
    margin: 0 auto;
    width: 50%;
    padding: 20px;
    background-color: #141321;
    border: 1px solid #5a59d8;
    border-radius: 8px;
    text-align: center;
  }
  .box-wide:hover {
    background-color: #5a59d8;
  }
  body {
    font-family: "figtree", sans-serif, "Figtree";
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .box-wide2 {
    margin: 20% auto;
    width: 300px;
    height: 80%;
    background-color: #141321;
    border: 1px solid #5a59d8;
    border-radius: 8px;
    text-align: center;
    display: flex;
    align-items: center; 
    justify-content: center;
    text-decoration: none;
    display: flex;
}
  .box-wide2:hover {
    background-color: #5a59d8;
  }
