body {
  font-family: "circular", sans-serif;
  margin: 0;
  overscroll-behavior: contain;
  background-color: #141321;
}

.App {
  text-align: center;
  background-color: #1A1A2C;

}
.app-container {
  background-color: #141321;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #121223;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin: 5%;
  border-radius: 25px;
}
.yazı{
  margin: 5%;
  border-radius: 15px;
  padding-right: 5%;
  padding-left: 5%;
  padding-top: 2%;
}
.yazı2{
  margin-left: 10%;
  margin-top: 5%;
  margin-bottom: 5%;
  border-radius: 15px;
  padding-right: 5%;
  padding-left: 5%;
  padding-top: 2%;
}
.yazı3{
  border-radius: 15px;
  padding-right: 5%;
  padding-left: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
margin-bottom: 5%;
}
.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;

}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: figtree;
  src: url(../public/Figtree-VariableFont_wght.ttf);
}
.circular{
  font-family: figtree;
}